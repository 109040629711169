module controllers {
  export module tariff {
    interface ITariffBookNotesListCtrlScope extends ng.IScope {}

    interface ITariffBookNotesListParams extends ng.ui.IStateParamsService {}

    export class tariffBookNotesListCtrl {
      static $inject = [
        "$scope",
        "$rootScope",
        "generalService",
        "$q",
        "bsLoadingOverlayService",
        "entityService",
        "$timeout",
        "$uibModal",
        "tariffBookNotesService",
        "$state",
        "$stateParams",
        "$transitions",
        "statusService",
        "classificationValueService",
      ];

      IsLoading: boolean = false;
      ShowGrid: boolean = false;

      gridApi: uiGrid.IGridApi;

      myHook: any;

      tariffBookNotesSearch: interfaces.tariffbooknotes.ITariffBookNotesSearch =
        {};

      paginationOptions = {
        pageNumber: 1,
        pageSize: 25,
      };

      sortName: string;
      sortDirection: string;

      apiList: uiGrid.IGridApi;
      selected: uiGrid.IGridRow;

      selectedRowsArray: Array<interfaces.tariffbooknotes.ITariffBookNotesDisplay> =
        [];

      filterNames: string[] = [
        "CountryCode",
        "CodeType",
        "SectionCode",
        "TariffCode",
        "Title",
        "StartDate",
        "EndDate",
        "Status",
      ];

      filterList: interfaces.applicationcore.IKeyValue[] = [];

      constructor(
        private $scope: ITariffBookNotesListCtrlScope,
        private $rootScope: interfaces.applicationcore.IRootScope,
        public generalService: interfaces.applicationcore.IGeneralService,
        private $q: ng.IQService,
        private bsLoadingOverlayService,
        private entityService: interfaces.applicationcore.IEntityService,
        private $timeout: ng.ITimeoutService,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private tariffBookNotesService: interfaces.tariffbooknotes.ITariffBookNotesService,
        private $state: ng.ui.IStateService,
        private $stateParams: ITariffBookNotesListParams,
        private $transitions: ng.ui.core.ITransition,
        private statusService: interfaces.master.IStatusService,
        private classificationValueService: interfaces.applicationcore.IClassificationValueService
      ) {

        this.loadControls();  

        this.myHook = $transitions.onSuccess(
          {
            to: "auth.MasterData.TariffBookNotes",
            from: "auth.MasterData.TariffBookNotes.**",
          },
          () => {
            this.loadTariffBookNotes();
          }
        );

        $scope.$on("$destroy", () => {
          $transitions.onStart({}, this.myHook);
        });
      }

      addNewTariffNote(){
        this.$state.go("auth.MasterData.TariffBookNotes.Update", { tnnId: 0 });
      }


      loadControls() {
        this.ShowGrid = true;
        this.DoSearch();   
      }

      loadTariffBookNotes() {
        this.bsLoadingOverlayService.wrap(
          {
            referenceId: "tarifbooknotes",
          },
          () => {
            var searchObject: interfaces.applicationcore.ISearchObject = {
              filters: [],
              sorts: [],
            };

            angular.forEach(this.tariffBookNotesSearch, (n, key) => {
              if (n["Id"]) {
                searchObject.filters.push({ Name: key, Value: n.Id });
              } else {
                searchObject.filters.push({ Name: key, Value: n });
              }
            });

            if (this.gridApi) {
              var grid = this.gridApi.grid;
              angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                if (n.filters[0].term) {
                  searchObject.filters.push({
                    Name: n.name,
                    Value: n.filters[0].term,
                  });
                }

                if (n.sort.direction) {
                  searchObject.sorts.push({
                    Name: n.name,
                    SortDirection: n.sort.direction === "asc" ? 0 : 1,
                    SortOrder: n.sort.priority,
                  });
                }
              });
            }

            var params = {
              connectSearch: searchObject,
              numberRecords: this.paginationOptions.pageSize,
              pageNumber: this.paginationOptions.pageNumber,
            };

            return this.tariffBookNotesService.getTariffBookNotes().query(
              params,
              (data) => {
                this.IsLoading = false;
                this.gvwList.data = data;

                if (data[0]) {
                  this.gvwList.totalItems = data[0].NumberRecords;
                } else {
                  this.gvwList.totalItems = 0;
                }
                this.$timeout(() => {
                  if (this.apiList) {
                    this.getFilters();
                    this.setFilters();
                  }
                });

                this.$timeout(() => {
                  if (this.selected) {
                    var index = _.findIndex(this.gvwList.data, (o) => {
                      return o.Id === this.selected.entity.Id;
                    });
                    this.apiList.selection.selectRow(this.gvwList.data[index]);
                  }
                });
              },
              (errorResponse) => {
                this.generalService.displayMessageHandler(
                  <interfaces.applicationcore.IMessageHandler>errorResponse.data
                );
              }
            ).$promise;
          }
        );
      }

      DoSearch() {
        this.IsLoading = true;

        var searchObject: interfaces.applicationcore.ISearchObject = {
          filters: [],
          sorts: [],
        };

        angular.forEach(this.tariffBookNotesSearch, (n, key) => {
          if (n) {
            if (n["Id"]) {
              searchObject.filters.push({ Name: key, Value: n.Id });
            } else {
              searchObject.filters.push({ Name: key, Value: n });
            }
          }
        });

        if (this.apiList) {
          var grid = this.apiList.grid;

          angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
            if (n.filters[0].term) {
              searchObject.filters.push({
                Name: n.name,
                Value: n.filters[0].term,
              });
            }

            if (n.sort.direction) {
              searchObject.sorts.push({
                Name: n.name,
                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                SortOrder: n.sort.priority,
              });
            }
          });
        }

        var params = {
          connectSearch: searchObject,
          numberRecords: this.paginationOptions.pageSize,
          pageNumber: this.paginationOptions.pageNumber,
        };

        this.tariffBookNotesService.getTariffBookNotes().query(
          params,
          (data) => {
            this.IsLoading = false;
            this.gvwList.data = data;
            this.selectedRowsArray = [];

            if (data[0]) {
              this.gvwList.totalItems = data[0].NumberRecords;
            } else {
              this.gvwList.totalItems = 0;
            }

            this.$timeout(() => {
              if (this.apiList) {
                this.getFilters();
                this.setFilters();
              }
            });

            this.$timeout(() => {
              if (this.selected) {
                var index = _.findIndex(this.gvwList.data, (o) => {
                  return o.Id === this.selected.entity.Id;
                });
                this.apiList.selection.selectRow(this.gvwList.data[index]);
              }
            });
          },
          (data) => {
            this.IsLoading = false;
            this.gvwList.data = [];
            this.gvwList.totalItems = 0;
            this.generalService.displayMessageHandler(
              <interfaces.applicationcore.IMessageHandler>data.data
            );
          }
        );
      }

      getFilters() {
        _.forEach(this.filterNames, (name: string) => {
          if (
            this.apiList.grid.getColumn(name).filters[0].term ||
            this.apiList.grid.getColumn(name).filters[0].term == ""
          ) {
            var keyValue: interfaces.applicationcore.IKeyValue = _.find(
              this.filterList,
              _.matchesProperty("key", name)
            );

            if (keyValue) {
              var index = _.findIndex(this.filterList, (o) => {
                return o.key === name;
              });

              this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                key: name,
                value: this.apiList.grid.getColumn(name).filters[0].term,
              };
            } else {
              this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                key: name,
                value: this.apiList.grid.getColumn(name).filters[0].term,
              });
            }
          }
        });
      }

      setFilters() {
        if (this.filterList) {
          _.forEach(
            this.filterList,
            (keyValue: interfaces.applicationcore.IKeyValue) => {
              this.apiList.grid.getColumn(keyValue.key).filters[0].term =
                keyValue.value;
            }
          );
        }

        if (this.sortName && this.sortDirection) {
          this.apiList.grid.getColumn(this.sortName).sort.direction =
            this.sortDirection;
        }
      }

      ListPageChange(newPage, pageSize) {
        this.paginationOptions.pageNumber = newPage;
        this.paginationOptions.pageSize = pageSize;

        this.DoSearch();
      }

      ListShortChange(gridApi) {
        this.DoSearch();
      }

      ListFilterChange(gridApi: uiGrid.IGridApi) {
        _.forEach(gridApi.grid.columns, (c) => {
          if (!c.filters[0].term) {
            var keyValue: interfaces.applicationcore.IKeyValue = _.find(
              this.filterList,
              _.matchesProperty("key", c.field)
            );

            if (keyValue) {
              this.filterList.splice(this.filterList.indexOf(keyValue), 1);
            }
          }
        });

        this.DoSearch();
      }

      registerGridApi(gridApi: uiGrid.IGridApi) {
        this.apiList = gridApi;

        this.apiList.core.on.filterChanged(this.$scope, () => {
          this.ListFilterChange(gridApi);
        });
        this.apiList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
          this.ListShortChange(gridApi);

          if (sortColumns[0]) {
            this.sortName = sortColumns[0].name;
            this.sortDirection = sortColumns[0].sort.direction;
          } else {
            this.sortName = "";
            this.sortDirection = "";
          }
        });
        this.apiList.pagination.on.paginationChanged(
          this.$scope,
          (newPage, pageSize) => {
            this.ListPageChange(newPage, pageSize);
          }
        );

        this.apiList.core.on.rowsRendered(this.$scope, () => {
          for (let selectedRow of this.selectedRowsArray) {
            for (var row of this.gvwList.data) {
              if (selectedRow.Id === row.Id) {
                this.apiList.selection.selectRow(row);
              }
            }
          }
        });

        this.apiList.selection.on.rowSelectionChanged(
          this.$scope,
          (selected) => {
            this.selected = selected;

            if (!selected.isSelected) {
              for (let selectedRow of this.selectedRowsArray) {
                if (selectedRow.Id === selected.entity.Id) {
                  this.selectedRowsArray.splice(
                    this.selectedRowsArray.indexOf(selectedRow),
                    1
                  );
                }
              }
            } else {
              var Exists: number = 0;
              for (let selectedRow of this.selectedRowsArray) {
                if (selectedRow.Id === selected.entity.Id) {
                  Exists = 1;
                }
              }

              if (Exists === 0) {
                this.selectedRowsArray.push(selected.entity);
              }
            }
          }
        );

        this.$timeout(() => {
          if (this.selected)
            this.apiList.selection.selectRow(this.selected.entity);
        });
      }

      gvwList: uiGrid.IGridOptions = {
        data: [],
        enableFiltering: true,
        useExternalFiltering: true,
        enableCellEdit: false,
        useExternalSorting: true,
        multiSelect: false,
        enableColumnResizing: true,
        enableRowSelection: true,
        enableFullRowSelection: true,
        paginationPageSizes: [25, 50, 75, 100],
        paginationPageSize: 25,
        useExternalPagination: true,
        enableRowHeaderSelection: false,
        enableHorizontalScrollbar: 2,
        enableVerticalScrollbar: 1,
        onRegisterApi: (gridApi) => {
          this.registerGridApi(gridApi);
          this.apiList.cellNav.on.navigate(this.$scope, (newCol) => {
            this.$timeout(() => {
              this.apiList.selection.selectRow(newCol.row.entity);
            });
          });
        },
        columnDefs: [
          {
            name: "ID",
            displayName: "ID",
            field: "Id",
            visible: false,
            enableFiltering: false,
          },
          {
            name: "EDIT",
            displayName: "",
            enableFiltering: false,
            cellTemplate: `
                            <div> 
                                <button type="button" ui-sref="auth.MasterData.TariffBookNotes.Update({ tnnId: row.entity.Id })" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
            enableSorting: false,
            enableColumnMenu: false,
            width: 35,
          },
          {
            name: "Title",
            displayName: "Title",
            field: "Title",
            width: 280,
            cellTemplate:
              '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
          },            
          {
            name: "CountryCode",
            displayName: "Country Code",
            field: "CountryCode",
            width: 120,
            cellTemplate:
              '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
          },
          {
            name: "CodeType",
            displayName: "Code Type",
            field: "CodeType",
            width: 220,
            cellTemplate:
              '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
          },
          {
            name: "SectionCode",
            displayName: "Section Code",
            field: "SectionCode",
            width: 120,
            cellTemplate:
              '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
          },       
          {
            name: "TariffCode",
            displayName: "Tariff Code",
            field: "TariffCode",
            width: 120,
            cellTemplate:
              '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
          },       
          , {
            name: 'StartDate',
            displayName: 'Start Date',
            field: 'StartDate',
            cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
            width: 100,
            type: "date",
            filterHeaderTemplate: `
                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                    <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="startDate"></gts-date>
                </div>`,
        }, {
            name: 'EndDate',
            displayName: 'End Date',
            field: 'EndDate',
            cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
            width: 100,
            type: "date",
            filterHeaderTemplate: `
                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                    <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="endDate"></gts-date>
                </div>`,
        },          
                         
          {
            name: "Status",
            displayName: "Status",
            field: "Status",
            cellTemplate:
              '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
            width: 140,
            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
          },
        ],
      };
    }

    angular
      .module("app")
      .controller(
        "tariffBookNotesListCtrl",
        controllers.tariff.tariffBookNotesListCtrl
      );
  }
}
